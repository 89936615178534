@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
#wrapper {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "JostBold";
  src: local("JostBold"), url('../src/assets/fonts/Jost-Bold.ttf');
  font-weight: bold;
};

@font-face {
  font-family: "JostSemiBold";
  src: local('JostSemiBold'), url('../src/assets/fonts/Jost-SemiBold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: "JostMedium";
  src: local('JostMedium'), url('../src/assets/fonts/Jost-Medium.ttf');
  font-weight: 500;
};

@font-face {
  font-family: "JostRegular";
  src: local('JostRegular'), url('../src/assets/fonts/Jost-Regular.ttf');
  font-weight: 400;
}
